.Minimum {
  margin:1em;
  margin-top: 1em;
  margin-bottom:1em;
  }

.MinimumLabel {
  display: inline;
  margin-left: 2em;
  margin-right: .8em;
  size:1em;
}

.MinimumDescription {
  margin-left:1em;
}
