.InputBox {
  display: inline;
  margin: 1em;
  width: 80em;
  border: 2px solid gray;
  padding: 0.5em;
}

.Input {
  display: inline;
  width: 35em;
  margin: 0;
  position: relative;
  /*border:2px solid red;*/
  padding: 0;
}

.StationInput {
  margin: 0.5em;
  font-size: 0.8em;
}

label {
  font-style: bold;
}

.AutocompleteBox {
  position: absolute;
  left: 6px;
  top: 19px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid gray;
}

.SuggestionItem {
  font-size: 0.8em;
  background: white;
  width: auto;
  margin: 0;
  padding: 0.1em;
}
.SuggestionItem:hover {
  background: #e8e8e8;
}

.Minimum {
  margin:1em;
  margin-top: 1em;
  margin-bottom:1em;
  }

.MinimumLabel {
  display: inline;
  margin-left: 2em;
  margin-right: .8em;
  size:1em;
}

.MinimumDescription {
  margin-left:1em;
}

.ResultTime {
  font-weight: bold;
}

.StationName {
  font-style: italic;
}

h3 {
  font-size: 2em;
}

.Results {
  margin-left: 1em;
}

.Result {
  margin-left: 1.5em;
  font-size:1.2em;
  border-bottom: 1px solid gray;
  padding-bottom: .2em;
  margin-bottom: .5em;
}

.BothRoutes {
}

.OneRoute0 {
  /*float:left;
  display:inline;*/
  width:50%;
  margin-left:1em;
  padding-left:1em;
  border-left:1px solid gray;
}
.OneRoute1 {
  /*float:right;
  display:inline;*/
  width:50%;
  margin-left:1em;
  padding-left:1em;
  border-left:1px solid gray;
}

.RouteTitle {
  font-size:1em;
  font-weight:normal;
  width:100%;
  text-decoration:underline;
}

.RouteStep {
  margin-left:2em;
  margin-bottom:.1em;
  padding:0em;
}

body {
  background-color: #eaeaea;
}

.Controls {
  border: 2px solid gray;
}

h1 {
  margin:0em;
  padding-left: 0.5em;
  padding-top:0.5em;
}

p {
  margin-bottom:.5em;
  padding: 1em;
  max-width: 65em;
}

.Submit {
  font-size:1.0em;
  margin:1em;
  }

