body {
  background-color: #eaeaea;
}

.Controls {
  border: 2px solid gray;
}

h1 {
  margin:0em;
  padding-left: 0.5em;
  padding-top:0.5em;
}

p {
  margin-bottom:.5em;
  padding: 1em;
  max-width: 65em;
}

.Submit {
  font-size:1.0em;
  margin:1em;
  }
